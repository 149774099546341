import {postCfg, getCfg}     from './db'
import {split, last, pipe}   from 'ramda'
import {DateTime}            from 'luxon'

const urlIpa = 'https://susanacanel.com/ipa.php/' // eslint-disable-line no-unused-vars

const armarElRequestDesdeElFormulario = datos => {
  const {sobrenombre, correo, asunto, mensaje: texto, tipo = 'comentario', referencia = null} = datos
  const articulo  = pipe(split('/'), last)(window.location.href)
  const estado    = 'pendiente'
  const estampado = DateTime.local().toISO().substring(0, 19)
  return {sobrenombre, correo, articulo, estampado, referencia: Number(referencia), estado, texto, tipo, asunto}
}

export const guardarDesdeUnFormulario = async datos => {
  try {
    const registro = armarElRequestDesdeElFormulario(datos)
    const config = {...postCfg, body: JSON.stringify(registro)}
    const url = urlIpa + 'tecomento/0'
    const response = await fetch(url, config)
    return response
  } catch (error) {
    throw new Error(error)
  }
}

export const mensaje = async id => {
  try {
    const url = id => 'https://susanacanel.com/ipa.php/mensaje/' + id
    const response = await fetch(url(id), getCfg)
    return response.json()
  } catch (error) {
    throw new Error(error)
  }
}

export const conversacion = async id => {
  try {
    const url = id => 'https://susanacanel.com/ipa.php/conversacion/' + id
    const response = await fetch(url(id), getCfg)
    return response.json()
  } catch (error) {
    throw new Error(error)
  }
}

export const consultar = async consulta => {
  try {
    const url = `https://susanacanel.com/api.php/records/mensajes${consulta}`
    const response = await fetch(url, getCfg)
    return response.json()
  } catch (error) {
    throw new Error(error)
  }
}


export const buscar = async claves => {
  try {
    const url      = `${urlIpa}buscar`
    const config   = {...postCfg, body: JSON.stringify({claves})}
    const response = await fetch(url, config)
    const json     = await response.json()
    return json
  } catch (error) {
    throw new Error(error)
  }
}
export const efectuarModificaciones = async modificaciones => {
  try {
    const url = `${urlIpa}modificaciones`
    const config = {...postCfg, body: JSON.stringify(modificaciones)}
    const response = await fetch(url, config)
    return response
  } catch (error) {
    throw new Error(error)
  }
}


export const conseguirMensajesPorTipoYEstado = async (tipo, estado) => {
  let query = ''
  query = (tipo !== 'todo') ? `?filter=tipo,eq,${tipo}` : ''
  query += (estado !== 'todo') ? `${(query.length > 0) ? '&' : '?'}filter=estado,eq,${estado}` : ''
  query += ((query.length > 0 ) ? '&' : '?') + `join=personas`
  return consultar(query)
}

export const mensajesPorTopic = async topic => {
  try {
    const url = topic => 'https://susanacanel.com/ipa.php/que_dicen_de?articulo=' + topic
    const response = await fetch(url(topic), getCfg)
    return response.json()
  } catch (error) {
    throw new Error(error)
  }
}
